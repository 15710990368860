
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class ManagementCompany extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  public cardList = [
    {
      title: "退去立会い",
      content:
        "賃貸借契約書をご確認させていただき、退去時の立会いから敷金精算業務、退去精算書の作成まで、不動産会社さまの代行として執り行います。",
      subContent: "[立会い・書類作成のみは手数料¥5,000]",
      img: "./../../assets/taikyotachiai.png",
    },
    {
      title: "原状回復・リフォーム提案 お見積",
      content:
        "ハウスクリーニングやエアコン戦場はもちろん、原状回復に加えリフォームやリノベーションのご提案も合わせてお見積を作成させていただきます。外壁塗装や園芸外構、立会いなしのお見積作成も承ります。",
      subContent: "[基本価格？]",
      img: "./../../assets/omitsumori.png",
    },
    {
      title: "アフター(クレーム)対応",
      content:
        "入居者さまからのアフター修理依頼(クレーム)は、退去につながってしまう重要な要件です。水回り、電気設備、内装の損傷など様々な事例がありますが、当社は自社施工ですので全てに対応可能です。専門知識のあるスタッフが迅速に現場へ伺い、施工まで対応いたします。",
      subContent: "",
      img: "./../../assets/claim-taiou.png",
    },
    {
      title: "スピード対応",
      content:
        "各種工事からハウスクリーニング、アフター対応まで自社施工ですので、各業者への依頼・連携・日程調整の連絡など、管理会社さまの手を煩わせることなく、一括でお任せいただけます。そのため、スムーズな進行で早期完了となります。",
      subContent: "",
      img: "./../../assets/speed-taiou.png",
    },
    {
      title: "保険工事",
      content:
        "漏水・火災・災害・過失など、各種保険適用での工事においては、保険会社への対応や見積作成、調査会社の現地確認への対応、施工まで、補修工事に関わる全てをお任せいただけます。",
      subContent: "",
      img: "./../../assets/hokenkouji.png",
    },
  ];
}
