<template>
  <div class="management-company">
    <div class="catch-copy-container">
      <div
        class="catch-copy-sub container is-fluid"
        style="transform: translateY(30%); height: 100%"
      >
        <div style="font-size: 1.7rem; font-weight: 700; padding: 10px">
          ラク賃で任せて安心
        </div>
        <div
          style="
            font-size: 1rem;
            font-weight: 500;
            padding: 10px;
            text-align: center;
          "
        >
          <div>
            賃貸物件のクレーム対応や退去立会・リフォームまで、任せて安心。家主様も管理会社様も、ラクして賃貸運営に専念していただけます。
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 3rem 0rem 3rem; background-color: #f5f5f5">
      <div class="container is-fluid">
        <div class="column is-full">
          <div
            style="font-size: 1.7rem; font-weight: 700; padding-bottom: 10px"
          >
            主な業務内容
          </div>
          <div style="padding-bottom: 30px">
            リフォームはもちろん、クレーム対応や退去立会いまで、不動産管理のお手伝いをいたします。
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/taikyotachiai.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    退去立会い
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    賃貸借契約書をご確認させていただき、退去時の立会いから敷金精算業務、退去精算書の作成まで、不動産会社さまの代行として執り行います。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500">
                    [立会い・書類作成のみは手数料¥5,000]
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/omitsumori.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    原状回復・リフォーム提案 お見積
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    賃貸借契約書をご確認させていただき、退去時の立会いから敷金精算業務、退去精算書の作成まで、不動産会社さまの代行として執り行います。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/claim-taiou.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    アフター(クレーム)対応
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    入居者さまからのアフター修理依頼(クレーム)は、退去につながってしまう重要な要件です。水回り、電気設備、内装の損傷など様々な事例がありますが、当社は自社施工ですので全てに対応可能です。専門知識のあるスタッフが迅速に現場へ伺い、施工まで対応いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/speed-1m.jpeg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    スピード対応
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    各種工事からハウスクリーニング、アフター対応まで自社施工ですので、各業者への依頼・連携・日程調整の連絡など、管理会社さまの手を煩わせることなく、一括でお任せいただけます。そのため、スムーズな進行で早期完了となります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../assets/5016333_m.jpg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    保険工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    漏水・火災・災害・過失など、各種保険適用での工事においては、保険会社への対応や見積作成、調査会社の現地確認への対応、施工まで、補修工事に関わる全てをお任せいただけます。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: left; padding: 30px; padding: 1rem 1.5rem 1rem">
      <div
        class="columns"
        style="padding: 5rem 0rem 4rem; display: flex; align-items: center"
      >
        <div class="column is-full">
          <div
            style="font-size: 1.7rem; font-weight: 700; padding-bottom: 10px"
          >
            業務フローのイメージ
          </div>
          <div style="padding-bottom: 30px">
            自社施工だから一括お任せでコスト低減。アフター対応もスピーディー。
          </div>
          <div style="height: 100%; width: 100%;text-align: center;">
            <img
              style="width: 100%; max-width: 500px;"
              src="./../../assets/management-image.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <ToContactContent />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class ManagementCompany extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  public cardList = [
    {
      title: "退去立会い",
      content:
        "賃貸借契約書をご確認させていただき、退去時の立会いから敷金精算業務、退去精算書の作成まで、不動産会社さまの代行として執り行います。",
      subContent: "[立会い・書類作成のみは手数料¥5,000]",
      img: "./../../assets/taikyotachiai.png",
    },
    {
      title: "原状回復・リフォーム提案 お見積",
      content:
        "ハウスクリーニングやエアコン戦場はもちろん、原状回復に加えリフォームやリノベーションのご提案も合わせてお見積を作成させていただきます。外壁塗装や園芸外構、立会いなしのお見積作成も承ります。",
      subContent: "[基本価格？]",
      img: "./../../assets/omitsumori.png",
    },
    {
      title: "アフター(クレーム)対応",
      content:
        "入居者さまからのアフター修理依頼(クレーム)は、退去につながってしまう重要な要件です。水回り、電気設備、内装の損傷など様々な事例がありますが、当社は自社施工ですので全てに対応可能です。専門知識のあるスタッフが迅速に現場へ伺い、施工まで対応いたします。",
      subContent: "",
      img: "./../../assets/claim-taiou.png",
    },
    {
      title: "スピード対応",
      content:
        "各種工事からハウスクリーニング、アフター対応まで自社施工ですので、各業者への依頼・連携・日程調整の連絡など、管理会社さまの手を煩わせることなく、一括でお任せいただけます。そのため、スムーズな進行で早期完了となります。",
      subContent: "",
      img: "./../../assets/speed-taiou.png",
    },
    {
      title: "保険工事",
      content:
        "漏水・火災・災害・過失など、各種保険適用での工事においては、保険会社への対応や見積作成、調査会社の現地確認への対応、施工まで、補修工事に関わる全てをお任せいただけます。",
      subContent: "",
      img: "./../../assets/hokenkouji.png",
    },
  ];
}
</script>
<style lang="scss">
.management-company {
  padding-top: 3.25rem;

  .catch-copy-container {
    height: 50vh;
    background: linear-gradient(
          130deg,
          rgba(231, 223, 223, 0.6),
          rgba(64, 65, 41, 0.5)
        )
        fixed,
      url("./../../assets/YqcK9t.png");
    // background: url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;
  }
}
</style>
